<template>
  <section class="container">
    <LangDropdown iconSize="35" class="container__languageModal" :currentLocale="getLocale" :optsLang="langs" @localeChange="changeLang" v-if="areLangsLoaded" />
    <div class="section signup-section" id="signup" v-bind="initData" :key="elmKey">
      <div class="signup-section-wrap" v-if="info">
        <div class="wrap-slider" v-if="hiddenCarrusell">
          <div :class="screen.width < 768 ? 'slogan-c' : 'slogan'" v-if="info.title">
            {{ info.title }}
          </div>
          <VueSlickCarouselc :lazyLoad="'progressive'" v-if="info.sliderRegister" v-bind="setting" class="wrap-slider--imgSlider">
            <template #prevArrow="">
              <div class="custom-arrow">
                <iconic name="prevArrowTransparent" />
              </div>
            </template>
            <div v-for="(lady, index) in info.sliderRegister[device]" :key="index">
              <div :style="`--image:url('${urlCloudFront(lady.image)}?v=3')`" class="imgSlider" width="100%"></div>
            </div>
            <template #nextArrow="">
              <div class="custom-arrow">
                <iconic name="nextArrowTransparent" />
              </div>
            </template>
          </VueSlickCarouselc>
          <div class="wrap-title" v-if="screen.width > 768">
            <div>
              <div>
                <router-link to="/">{{ infoFooter.title }}</router-link>
              </div>
            </div>
            <div class="social-icons">
              <template v-for="(social, i) in socialMedia">
                <div class="social-icon" v-if="social.url" @click="sanitizeUrl(social.url)" :key="i">
                  <iconic :svgCode="social.icon" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="signup-section-form" v-show="!isFormSubmitted" ref="containerForm">
          <form id="signup-data-form" method="post" enctype="multipart/form-data" v-show="!isSend">
            <div class="signup-section-title">
              <div class="signup-section-home" v-if="screen.width > 768">
                <router-link to="/">
                  <iconic name="arrowLeft" />
                  {{ info.homepage }}
                </router-link>
              </div>
            </div>
            <div class="signup-section-form-wrap">
              <span class="signup-section-title-sub sec-font">
                {{ info.subtitle }}
              </span>
              <div class="signup-section-form-title">{{ info.formWarn }}</div>
              <div class="signup-section-form-data-section" v-show="information">
                <div class="signup-section-form-data-input" id="youName" v-if="info.inputName">
                  <div role="label" required>{{ info.inputName.label }}</div>
                  <input type="text" name="youName" required :placeholder="info.inputName.placeholder" @change="addNameModel" @blur="validateInput" />
                </div>
                <div class="signup-section-form-data-input" id="youDate" v-if="info.inputDate">
                  <div role="label" required>{{ info.inputDate.label }}</div>
                  <DMYDate name="youDate" :placeholder="info.inputDate.placeholder" @date="(date) => (birthDayDate = date)" @isSuccess="deleteErrorParentClass" :value="birthDayDate" required />
                </div>
                <div class="signup-section-form-data-input" id="youEmail" v-if="info.inputEmail">
                  <div role="label" required>{{ info.inputEmail.label }}</div>
                  <input type="email" name="youEmail" required :placeholder="info.inputEmail.placeholder" @blur="validateInput" />
                </div>
                <div class="signup-section-form-data-input" id="youTel" v-if="info.inputPhone">
                  <div role="label" required>{{ info.inputPhone.label }}</div>
                  <div class="container-telInfo">
                    <InputPostalCode :currentPostalCode="currentPostalCode" @postalCode="updatePostalCode" :placeHolder="postalCodeLanguage" />
                    <div class="container__inpt-tel">
                      <input type="number" name="youTel" required :placeholder="info.inputPhone.placeholder" @blur="validateInput" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="signup-section-form-data-section">
                <div class="signup-section-form-data-input" id="youHow" v-if="info.inputHow">
                  <div role="label" required>{{ info.inputHow.label }}</div>
                  <input type="text" name="youHow" :placeholder="info.inputHow.placeholder" required @blur="validateInput" />
                </div>
                <div class="signup-section-form-data-input" v-if="info.optionHasModel" id="hasModel">
                  <div role="label" required>
                    {{ info.optionHasModel.label }}
                  </div>
                  <div role="select-radio" :placeholder="info.optionHasModel.label" required @click="validateOption('#youHasModel')" id="youHasModel">
                    <div role="option">
                      <input type="radio" id="hasModelYes" name="hasModel" value="yes" required />
                      <label for="hasModelYes">{{ info.optionHasModel.option1 }}</label>
                    </div>
                    <div role="option">
                      <input type="radio" id="hasModelNo" name="hasModel" value="no" required />
                      <label for="hasModelNo">{{ info.optionHasModel.option2 }}</label>
                    </div>
                  </div>
                </div>
                <div class="signup-section-form-data-input" v-if="info.optionCity" id="optionCity">
                  <div role="label" required>{{ info.optionCity.label }}</div>
                  <div role="select-radio" required :placeholder="info.optionCity.label" @click="validateOption('#youCity')" id="youCity">
                    <div role="option">
                      <input type="radio" id="fromCity2" name="fromCity" value="medellin" @click="hideElement" required />
                      <label for="fromCity2">{{ info.optionCity.option2 }}</label>
                    </div>
                    <div role="option">
                      <input type="radio" id="fromCity3" name="fromCity" value="pereira" @click="hideElement" required />
                      <label for="fromCity3">{{ info.optionCity.option3 }}</label>
                    </div>
                    <div role="option">
                      <input type="radio" id="fromCity4" name="fromCity" value="cucuta" @click="hideElement" required />
                      <label for="fromCity4">{{ info.optionCity.option4 }}</label>
                    </div>
                    <div role="option">
                      <input type="radio" id="fromCity1" name="fromCity" value="bogota" @click="hideElement" required />
                      <label for="fromCity1">{{ info.optionCity.option1 }}</label>
                    </div>
                    <div role="option" class="display-flex flex-middle">
                      <input type="radio" id="fromOtherCity" name="fromCity" value="other" required @click="selectCheckbox('#fromOtherCity')" />
                      <label for="fromOtherCity" w100>
                        <input role="other" name="otherCity" type="text" :placeholder="info.optionCity.optionOther" v-model="otherCityData" @input="selectCheckbox('#fromOtherCity')" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="signup-section-form-data-input" v-if="info.optionContact" id="optionContact">
                  <div role="label" required>
                    {{ info.optionContact.label }}
                  </div>
                  <div role="select-radio" required :placeholder="info.optionContact.label" @click="validateOption('#youContact')" id="youContact">
                    <div role="option">
                      <input type="checkbox" id="contactPhone" name="contactPhone" value="phone" />
                      <label for="contactPhone">{{ info.optionContact.option1 }}</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="contactWhatsapp" name="contactWhatsapp" value="whatsapp" />
                      <label for="contactWhatsapp">{{ info.optionContact.option2 }}</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="contactEmail" name="contactEmail" value="email" />
                      <label for="contactEmail">{{ info.optionContact.option3 }}</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="contactAll" name="contactAll" value="all" @click="selectAll('contactAll')" />
                      <label for="contactAll">{{ info.optionContact.optionAll }}</label>
                    </div>
                  </div>
                </div>
                <div class="signup-section-form-data-input" v-if="info.optionTime" id="optionTime">
                  <div role="label" required>{{ info.optionTime.label }}</div>
                  <div role="select-radio" required :placeholder="info.optionTime.label" @click="validateOption('#youTime')" id="youTime">
                    <div role="option">
                      <input type="checkbox" id="timeMorning" name="timeAvailable1" value="morning" />
                      <label for="timeMorning">{{ info.optionTime.option1 }} (06:00am - 2:00pm)</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="timeLate" name="timeAvailable2" value="late" />
                      <label for="timeLate">{{ info.optionTime.option2 }} (02:00pm - 10:00pm)</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="timeNight" name="timeAvailable3" value="night" />
                      <label for="timeNight">{{ info.optionTime.option3 }} (05:00pm - 02:00am)</label>
                    </div>
                    <div role="option">
                      <input type="checkbox" id="timeOpen" name="timeAvailableAll" value="open" @click="selectAll('timeAvailableAll')" />
                      <label for="timeOpen">{{ info.optionTime.optionAll }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="signup-section-form-data-section">
                <div class="signup-section-form-data-input" v-if="info.inputFacebook">
                  <div role="label">{{ info.inputFacebook.label }}</div>
                  <input type="text" name="fbAccount" :placeholder="info.inputFacebook.placeholder" />
                </div>
                <div class="signup-section-form-data-input" v-if="info.inputInstagram">
                  <div role="label">{{ info.inputInstagram.label }}</div>
                  <input type="text" name="itAccount" :placeholder="info.inputInstagram.placeholder" />
                </div>
                <div class="signup-section-photos">
                  <div class="signup-section-component">
                    <div class="selectFiles__wrapper">
                      <span class="selectFiles__photo selectFiles__photo--one" v-if="messagePhoto1">{{ info.inputPhoto1.placeholder }}</span>
                      <SignupInputFile
                        id="photo1"
                        v-if="info.inputPhoto1"
                        type="file"
                        name="photo1"
                        :label="info.inputPhoto1.label"
                        :placeholder="info.inputPhoto1.placeholder"
                        @change="validateInput"
                        @urlImg="addUrlImg"
                        required />
                    </div>
                    <div class="selectFiles__wrapper">
                      <span class="selectFiles__photo selectFiles__photo--two" v-if="messagePhoto2">{{ info.inputPhoto2.placeholder }}</span>
                      <SignupInputFile
                        id="photo2"
                        v-if="info.inputPhoto2"
                        type="file"
                        name="photo2"
                        :label="info.inputPhoto2.label"
                        :placeholder="info.inputPhoto2.placeholder"
                        @change="validateInput"
                        @urlImg="addUrlImg"
                        required />
                    </div>
                  </div>
                  <div class="signup-section-component">
                    <SignupInputFile v-if="info.inputPhoto3" @urlImg="addUrlImg" type="file" name="photo3" :label="info.inputPhoto3.label" :placeholder="info.inputPhoto3.placeholder" />
                    <SignupInputFile v-if="info.inputPhoto4" @urlImg="addUrlImg" type="file" name="photo4" :label="info.inputPhoto4.label" :placeholder="info.inputPhoto4.placeholder" />
                  </div>
                </div>
                <UploadVideo @videoModel="showVideoModel" @sendUrl="addUrlVideo" @fileVideoModel="addFileVideoModel" />
                <div class="signup-section-form-data-input" v-if="info.optionAge" id="adultAge">
                  <div role="label" required>{{ info.optionAge.label }}</div>
                  <div role="select-radio" required :placeholder="info.optionAge.label" @click="validateOption('#youOld')" id="youOld">
                    <div role="option">
                      <input type="radio" id="isAdultYes" name="isAdult" value="yes" />
                      <label for="isAdultYes">{{ info.optionAge.option1 }}</label>
                    </div>
                    <div role="option">
                      <input type="radio" id="isAdultNo" name="isAdult" value="no" />
                      <label for="isAdultNo">{{ info.optionAge.option2 }}</label>
                    </div>
                  </div>
                </div>
                <div class="signup-section-form-data-input" v-if="info.inputMessage">
                  <div role="label">{{ info.inputMessage.label }}</div>
                  <textarea name="youMessage" :placeholder="info.inputMessage.placeholder"></textarea>
                </div>
              </div>
              <div class="signup-section-form-data-section">
                <SignupDataAccept />
              </div>
              <div class="signup-section-form-options">
                <span class="reChapcha-alert" v-if="!isValidTurnstile && !isLoadingTurnstile">{{ localeLang.words.register.turnstileError }}</span>
                <CloudFareTurnstile ref="turnstile" :siteKey="siteKey" :callback="turnstileCallbackHandler" :errorCallback="onTurnstileErrorCallbackHandler" />
                <a class="primary-button button block pointer" @click="validateData" v-if="info.buttonSubmit">
                  {{ info.buttonSubmit.label }}
                </a>
              </div>
              <div class="signup-section-form-options">{{ infoFooter.copyRight }} {{ new Date().getFullYear() }}</div>
            </div>
          </form>
          <SendingForm :statusModal="isSend" :infoModel="currentRise" :lisentUploadFiles="lisentUploadFiles" v-if="isSend" />
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <ModalVideoExampleVue :videoModelFiles="urlVideoBlod" @changeStatus="showVideoModel" v-if="statusModal" />
  </section>
</template>
<script>
  import api from "../../api";
  import global from "../../assets/js/global";
  import Swal from "sweetalert2";
  import VueSlickCarouselc from "vue-slick-carousel";
  import Iconic from "@/plugins/iconic/iconic.vue";
  import InputPostalCode from "./InputPostalCode.vue";
  import store from "../../store/index.js";
  import UploadVideo from "../uploadVideo/uploadVideo.vue";
  import ModalVideoExampleVue from "../uploadVideo/ModalVideoChunks.vue";
  import CloudFareTurnstile from "./CloudFareTurnstile.vue";
  import axios from "@/api/axios";
  import LangDropdown from "./LangDropdown.vue";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      DMYDate: () => import("../inputs/DMYDate.vue"),
      SignupInputFile: () => import("./SignupInputFile.vue"),
      SignupDataAccept: () => import("./SignupDataAccept.vue"),
      SendingForm: () => import("../sendingForm/sendingForm.vue"),
      VueSlickCarouselc,
      Iconic,
      InputPostalCode,
      UploadVideo,
      ModalVideoExampleVue,
      CloudFareTurnstile,
      LangDropdown,
    },
    metaInfo: {
      title: "Models 1A - Sign Up",
    },
    data: function () {
      return {
        info: [],
        currentPostalCode: "",
        elmKey: 0,
        birthDayDate: "",
        photos: false,
        agency: false,
        information: true,
        infoFooter: [],
        invalidFields: [],
        fieldNamesDictionary: {},
        setting: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          cancelable: false,
          centerMode: false,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
              },
            },
            {
              breakpoint: 980,
              settings: {
                arrows: false,
              },
            },
            {
              breakpoint: 426,
              settings: {
                arrows: false,
              },
            },
          ],
        },
        messagePhoto1: false,
        messagePhoto2: false,
        dataCountry: false,
        dataOther: true,
        countries: [],
        data: [],
        socialMedia: [],
        selected: "",
        cities: ["medellin", "cucuta", "bogota", "pereira"],
        allCities: [],
        states: [],
        errorCity: false,
        state: {},
        device: "",
        radioCity: "",
        answer: "",
        value: "",
        time: "",
        ageChecked: "",
        lang: "",
        otherCityData: "",
        statusModal: false,
        videoModelFile: "",
        urlVideoBlod: "",
        isSend: false,
        currentRise: { info: false, photos: [], videoModels: "", name: "" },
        isFormSubmitted: false,
        translate: null,
        hiddenCarrusell: true,
        siteKey: process.env.VUE_APP_CF_SITE_KEY,
        isValidTurnstile: false,
        isLoadingTurnstile: true,
        langs: [],
      };
    },
    computed: {
      ...mapGetters(["getLocale"]),
      initData: async function () {
        await this.getLanguage();
        await this.getCountriesInfo();
      },
      screen() {
        return this.$store.getters.screen;
      },
      truncateText() {
        const invalidText = this.invalidFields.join(", ");
        const msg = `${this.localeLang.words.register.error} ${invalidText}`;

        return msg.length > 120 ? `${msg.slice(0, 120)}...` : msg;
      },
      postalCodeLanguage() {
        return this.translate;
      },
      areLangsLoaded() {
        return this.langs.length > 0;
      },
    },
    watch: {
      isSend(val) {
        const form = document.querySelector(".signup-section-form");
        const footer = document.querySelector(".footer");
        const header = document.querySelector(".header");
        const btn = document.querySelector(".shadow-movil");
        const display = val ? "none" : "block";
        header && (header.style.display = display);
        footer && (footer.style.display = display);
        btn && (btn.style.display = display);
        form.style.background = val ? "#fff" : "";
      },
    },
    methods: {
      ...mapActions(["changeLanguage"]),
      selectCheckbox: function (selector) {
        this.radioCity = "";
        const checkbox = document.querySelector(selector);
        const inputOtherCity = document.getElementsByName("otherCity")[0];
        inputOtherCity.focus();
        checkbox.checked = this.otherCityData.length > 0;
        this.validateOption("#youCity");
      },
      selectAll(check) {
        const options = {
          timeAvailableAll: ["timeOpen", "timeMorning", "timeLate", "timeNight"],
          contactAll: ["contactAll", "contactPhone", "contactWhatsapp", "contactEmail"],
        };
        const firstCheckbox = document.querySelector(`#${options[check][0]}`);
        const newState = !firstCheckbox.checked;
        options[check].forEach((item) => {
          const checkbox = document.querySelector(`#${item}`);
          checkbox.checked = !newState;
        });
      },

      sanitizeUrl(url) {
        window.open(url, "_blank");
      },
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      updatePostalCode: function (value) {
        this.currentPostalCode = value;
      },
      async dataFooter() {
        let response = await api.get(`${process.env.VUE_APP_API_HOST}homeLanguages`);
        this.infoFooter = response.data.footer;
      },
      getLanguage: async function () {
        let dataLang = await store.getters["dataLang"];
        this.langs = dataLang.header.languages;
        this.socialMedia = this.$global.sortSocialMedia(dataLang.resource.social_media);
        let response = await api.get(`${process.env.VUE_APP_API_HOST}signupLanguages`);
        this.info = response.data;
        this.initializeFieldNames();
        if (this.$screen.width < 768) {
          this.device = "mobile";
        } else {
          this.device = "desktop";
        }
        this.localeLang = await this.$store.getters.localeLang;
        this.translate = this.localeLang.words.country;
      },
      hideElement: function () {
        const otherCity = document.getElementsByName("otherCity")[0];
        this.otherCityData = "";
        this.successInput(otherCity);
      },
      errorInput: function (el) {
        el.nextElementSibling ? el.nextElementSibling.remove() : "";
        const message = el.getAttribute("placeholder") || "";
        el.insertAdjacentHTML("afterend", '<div class="tooltip data-error">' + message + "</div>");
        el.classList.add("data-error");

        const fieldName = el.name || el.id;
        if (this.fieldNamesDictionary[fieldName]) {
          this.invalidFields.push(this.fieldNamesDictionary[fieldName]);
        } else {
          this.invalidFields.push(fieldName);
        }
      },
      successInput: function (el) {
        el.nextElementSibling ? el.nextElementSibling.remove() : "";
        el.classList.remove("data-error");
        this.deleteErrorParentClass(el);
      },
      deleteErrorParentClass(el) {
        let parent = el.parentElement;

        for (let i = 0; i <= 3; i++) {
          if (parent.classList.contains("error")) {
            parent.classList.remove("error");

            break;
          }

          parent = parent.parentElement;
        }
      },
      getCountriesInfo: async function () {
        let response = await api.get(`${process.env.VUE_APP_API_HOST}countries`);
        this.countries = Object.entries(response.data).map(([key, country]) => {
          return country;
        });
      },
      validateInput(el) {
        const _this = this;
        const _el = el?.srcElement || el;
        const _val = _el?.value?.trim?.() || "";

        const dicValidations = {
          text: () => !!_val,
          date: () => !!_val,
          email: () => global.validEmail(_val),
          number: () => global.validPhone(_val),
          file: () => {
            const fileExts = /(\.jpg|\.jpeg|\.png|\.gif|\.heic)$/i;
            const isValid = !!_el.files?.length && !!fileExts.test(_val);

            const dicImage = {
              photo1: "messagePhoto1",
              photo2: "messagePhoto2",
            };

            const varInThis = dicImage?.[_el?.name];
            varInThis && (this[varInThis] = !isValid);

            return isValid;
          },
        };

        const isValid = dicValidations?.[_el.type]?.();

        if (isValid) {
          _this.successInput(_el);
          return;
        }

        _this.errorInput(_el);
      },
      validateOption: function (el) {
        const _this = this;
        let isSelected = false;

        const myElm = typeof el === "string" ? document.querySelector(el) : el;
        let _inputs;

        if (typeof el === "string") {
          _inputs = document.querySelectorAll(el + " input");
        } else if (typeof el === "object") {
          _inputs = el.getElementsByTagName("input");
        } else {
          _inputs = [];
        }

        [].forEach.call(_inputs, function (_el) {
          if (_el.checked === true) {
            isSelected = true;
          }
        });

        const targetElement = myElm ? myElm : el;
        if (isSelected) {
          _this.successInput(targetElement);
        } else {
          _this.errorInput(targetElement);
        }
      },

      validateData: function () {
        const _this = this;

        if (!_this.isValidTurnstile) {
          return;
        }

        this.invalidFields = [];

        let [getInputs, getChecks] = ['input:not([type="checkbox"]):not([type="radio"])[required="required"]', 'div[role="select-radio"][required="required"]'];
        const fmOptions = document.querySelectorAll(`${getInputs}, ${getChecks}`);

        [].forEach.call(fmOptions, (el) => {
          if (el.tagName === "INPUT") {
            this.validateInput(el);
          } else {
            this.validateOption(el);
          }
        });

        this.checkDataTerms();
      },
      validateDataError: function () {
        const alert = Swal.fire({
          text: this.localeLang.signup.field_errors_message,
          icon: "error",
          confirmButtonText: this.info.validateDataError.confirmButtonText,
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });

        alert.then(async () => {
          await this.sleep(350);
          const fieldError = document.querySelector(".tooltip.data-error")?.closest(".signup-section-form-data-input");
          if (fieldError) {
            fieldError.scrollIntoView({ behavior: "smooth" });
          }
        });
      },
      checkDataTerms: function () {
        const termsAccepted = document.querySelector(".privacy-accept input[name='privacyCheck']").checked;
        if (!termsAccepted) {
          this.validateDataError();
          return;
        }
        this.dataSend();
      },
      goToFirstFieldNotEntered() {
        const requiredfields = document.querySelectorAll(".error, .data-error");
        const [firstRequiredField] = requiredfields;

        if (!firstRequiredField) {
          return;
        }

        setTimeout(() => {
          firstRequiredField.scrollIntoView({ behavior: "smooth", block: "center" });
          firstRequiredField.focus();
        }, 300);
      },
      dataSend: async function () {
        const _this = this;
        const _host = `${process.env.VUE_APP_API_HOST}signup/registerModel`;
        const _data = new FormData(document.getElementById("signup-data-form"));

        _data.append("country", this.selected.country_name);
        _data.append("inputPostalCode", this.currentPostalCode);
        _data.append("videoModel", this.videoModelFile);
        _data.append("siteRegister", "671ff98fd596630895230d5d");

        const values = new Map([..._data]);
        const formValues = Object.fromEntries(values);

        let webAnswer = document.getElementsByName("hasModel");
        let age = document.getElementsByName("isAdult");
        let citiesRadios = document.getElementsByName("fromCity");

        let halfContact = [];
        let contactOne = document.getElementById("contactPhone");
        contactOne.checked ? halfContact.push(contactOne.value) : "";
        let contactTwo = document.getElementById("contactWhatsapp");
        contactTwo.checked ? halfContact.push(contactTwo.value) : "";
        let contactThree = document.getElementById("contactEmail");
        contactThree.checked ? halfContact.push(contactThree.value) : "";
        let contactFour = document.getElementById("contactAll");
        contactFour.checked ? halfContact.push(contactFour.value) : "";

        let availableTime = [];
        let scheduleOne = document.getElementById("timeMorning");
        scheduleOne.checked ? availableTime.push(scheduleOne.value) : "";
        let scheduleTwo = document.getElementById("timeLate");
        scheduleTwo.checked ? availableTime.push(scheduleTwo.value) : "";
        let scheduleThree = document.getElementById("timeNight");
        scheduleThree.checked ? availableTime.push(scheduleThree.value) : "";
        let scheduleFour = document.getElementById("timeOpen");
        scheduleFour.checked ? availableTime.push(scheduleFour.value) : "";

        for (let i = 0; i < webAnswer.length; i++) {
          if (webAnswer[i].checked) {
            this.answer = webAnswer[i].value;
          }
        }
        for (let i = 0; i < citiesRadios.length; i++) {
          if (citiesRadios[i].checked) {
            this.radioCity = citiesRadios[i].value;
          }
        }

        for (let i = 0; i < halfContact.length; i++) {
          this.value = halfContact[i];
        }
        for (let i = 0; i < availableTime.length; i++) {
          this.time = availableTime[i];
        }
        for (let i = 0; i < age.length; i++) {
          if (age[i].checked) {
            this.ageChecked = age[i].value;
          }
        }

        let objetValidate = {
          youName: formValues["youName"],
          youDate: formValues["youDate"],
          youEmail: formValues["youEmail"],
          youTel: formValues["youTel"],
          youHow: formValues["youHow"],
          hasModel: this.answer,
          optionCity: this.radioCity,
          optionContact: this.value,
          optionTime: this.time,
          photo1: formValues["photo1"]["name"],
          photo2: formValues["photo2"]["name"],
          adultAge: this.ageChecked,
        };

        let readyToSend = true;
        const errors = document.querySelectorAll(".tooltip.data-error");

        for (let clave in objetValidate) {
          if (objetValidate.hasOwnProperty(clave) && objetValidate[clave] === "") {
            document.getElementById(`${clave}`).classList.add("error");
            if (formValues.photo1.name === "") {
              document.getElementById("photo1").classList.add("error");
              this.messagePhoto1 = true;
            }
            if (formValues.photo2.name === "") {
              document.getElementById("photo2").classList.add("error");
              this.messagePhoto2 = true;
            }
            readyToSend = false;
          } else {
            document.getElementById(`${clave}`).classList.remove("error");
          }
        }
        if (readyToSend && !errors.length) {
          const validateEmail = await api.post(`${process.env.VUE_APP_API_HOST}signup/validateEmail`, {
            email: formValues["youEmail"],
            siteRegister: formValues["siteRegister"],
          });

          if (!validateEmail.data.success) {
            this.dataSendError(validateEmail);
            return;
          }
          const _sending = _this.dataSending();
          const _send = await api
            .post(_host, _data)
            .then(function (response) {
              if (response.data.state) {
                _this.elmKey++;
                _this.dataSendSuccess(response);
              } else {
                if (response.data.field) {
                  const elError = document.querySelector(`[name=${response.data.field}]`);
                  global.scrollToEl({
                    el: `[name=${response.data.field}]`,
                    topgap: 60,
                  });
                  _this.errorInput(elError);
                }
                _this.dataSendError(response);
              }
            })
            .catch(function (response) {
              _this.dataSendError(response);
            });
        } else {
          Swal.fire({
            title: this.localeLang.words.register.tittle,
            html: this.truncateText,
            icon: "warning",
            confirmButtonText: this.info.dataSendError.confirmButtonText,
            timerProgressBar: true,
            confirmButtonColor: "#bd0909",
          });
          this.goToFirstFieldNotEntered();
        }
      },
      dataSending: function () {
        this.isSend = true;
        if (window.innerWidth <= 981) {
          this.hiddenCarrusell = false;
        }
      },
      dataSendError: function (response) {
        this.isSend = false;
        Swal.close();
        Swal.fire({
          title: this.info.dataSendError.title,
          html: this.lang === "es" && response.data ? response.data.message.es : response.data.message.en,
          icon: "warning",
          confirmButtonText: this.info.dataSendError.confirmButtonText,
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });
        this.goToFirstFieldNotEntered();
      },
      dataSendSuccess: function (response) {
        this.$store.state.signup.saved = response.data.saved;
        Swal.close();
        localStorage.setItem("successRegister", "true");
        this.isFormSubmitted = true;
        this.$router.push({ name: "SignupSuccess" });
      },
      showVideoModel(event) {
        this.urlVideoBlod = event;
        this.statusModal = !this.statusModal;
      },
      addFileVideoModel(event) {
        this.videoModelFile = event;
      },
      addUrlImg(url) {
        const indexPhotos = this.currentRise.photos.findIndex((photo) => photo === url);
        if (indexPhotos === -1) {
          this.currentRise.photos.push(url);
          return;
        }
        this.currentRise.photos.splice(indexPhotos, 1);
      },
      initializeFieldNames() {
        this.fieldNamesDictionary = {
          youName: this.info.inputName.label,
          youDate: this.info.inputDate.label,
          youEmail: this.info.inputEmail.label,
          youTel: this.info.inputPhone.label,
          youHow: this.info.inputHow.label,
          youHasModel: this.info.optionHasModel.label,
          youCity: this.info.optionCity.label,
          youContact: this.info.optionContact.label,
          youTime: this.info.optionTime.label,
          youOld: this.info.optionAge.label,
          photo1: this.info.inputPhoto1 ? this.info.inputPhoto1.label : "",
          photo2: this.info.inputPhoto2 ? this.info.inputPhoto2.label : "",
        };
      },
      addUrlVideo(url) {
        this.currentRise.videoModels = url;
      },
      addNameModel(name) {
        let nameModel = name.target.value;
        this.currentRise.name = nameModel;
      },
      lisentUploadFiles(totalProgress) {
        this.isSend = !isNaN(totalProgress) && totalProgress < 100;
      },
      async turnstileCallbackHandler(token) {
        try {
          const { data } = await axios.post("/humanVerify/cloudflare", { token });
          const { success } = data;

          this.isValidTurnstile = success;
          this.isLoadingTurnstile = false;

          if (!success) {
            this.$refs.turnstile.resetTurnstile();
          }
        } catch (error) {
          this.onTurnstileErrorCallbackHandler(error.message);
        }
      },
      onTurnstileErrorCallbackHandler(error) {
        const isCriticalError = this.handleTurnstileError(error);

        this.isValidTurnstile = !isCriticalError;
        this.isLoadingTurnstile = false;

        console.error("[Cloudflare Turnstile]", error);
      },
      handleTurnstileError(error) {
        const criticalErrors = [
          "102",
          "103",
          "104",
          "106", // Invalid Parameters
          "110100",
          "110110", // Invalid sitekey
          "110200", // Unknown domain
          "110420",
          "110430", // Invalid action or cData
          "110510", // Inconsistent user-agent,
          "300",
          "600", // Challenge execution failure
        ];
        const errorParts = error
          .trim()
          .split(" ")
          .filter((part) => !isNaN(part));

        return errorParts.some((errorPart) => criticalErrors.some((codeError) => errorPart.startsWith(codeError)));
      },
      changeLang: async function (lang) {
        this.lang = lang;
        await this.changeLanguage(lang);
      },
    },
    mounted: async function () {
      this.$root.$on("refreshlang", (data) => {
        this.getLanguage();
      });
      await this.dataFooter();
      this.lang = localStorage.getItem("user-language");
    },
    beforeRouteEnter(to, from, next) {
      const mbody = document.querySelector("body");
      mbody.classList.add("signup");
      next();
    },
    beforeRouteLeave(to, from, next) {
      const mbody = document.querySelector("body");
      mbody.classList.remove("signup");
      next();
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .container {
    position: relative;
    &__languageModal {
      padding: $space-12;
      display: none;
      position: absolute;
      right: 1%;
      top: 1%;
      z-index: 22;
    }
  }

  .error {
    border: 1px solid #bd0909;
    padding: 10px;
    border-radius: $radius-6;
    transition: all 0.5 ease-in-out;
  }
  #otherCity1 {
    padding-left: $space-6;
  }
  .select__country {
    padding: 7.5px;
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    font: 400 100% Montserrat;
    box-shadow: 0 0 0;
  }
  .reChapcha {
    width: fit-content;
    margin: auto;
    &-alert {
      color: $primary_color;
      font-weight: bold;
    }
  }
  .signup-section {
    background-color: $white_smoke;
    &-home {
      width: fit-content;
      color: $primary_color;
      font-size: 16px;
      margin-bottom: $space-16;
      text-align: left;
      cursor: pointer;
    }
    &-wrap {
      max-width: 100%;
      display: flex;
      height: 100%;
      .wrap-slider {
        width: 50%;
        height: 100%;
        position: relative;
        .slogan,
        .slogan-c {
          background-color: $primary_color;
          border-top-right-radius: $radius-12;
          border-bottom-right-radius: $radius-12;
          font-family: "BebasKai", sans-serif;
          width: fit-content;
          position: absolute;
          text-align: left;
          padding: $space-12;
          color: #fff;
          z-index: 10;
          opacity: 0.8;
        }
        .slogan {
          font-size: 40px;
          font-weight: 400;
          top: 83%;
        }
        .slogan-c {
          font-size: 18px;
          font-weight: 300;
          top: 88%;
        }
        &--imgSlider {
          height: 100vh;
          width: 100%;
          .imgSlider {
            background-image: var(--image);
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100vh;
          }
        }
      }
      .wrap-title {
        display: flex;
        justify-content: space-between;
        padding: 15px 35px 10px;
        z-index: 20;
        position: absolute;
        width: 100%;
        height: 130px;
        top: 0px;
        left: 0px;
        color: $primary_color;
        font-weight: 400;
        font-size: 42px;
        font-style: normal;
        background: linear-gradient(to bottom, #020202bd, rgba(255, 255, 255, 0));
        font-family: "RoundedElegance", sans-serif;
        :nth-child(2) {
          font-size: 16px;
        }
        .wrap-title-cities {
          font-family: Montserrat, sans-serif;
          text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.25);
        }
        .social-icons {
          margin-top: 5px;
          .social-icon {
            background-color: $primary_color;
            color: #fff;
            margin-right: 10px;
            display: inline-block;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 6px 0 4px 7px;
          }
        }
      }
      .slick-prev {
        left: 4%;
      }
      .slick-next {
        right: 10%;
      }
      .custom-arrow {
        margin: auto;
        &:hover {
          .iconic {
            color: $primary_color;
          }
        }
        .iconic {
          font-size: 80px;
          text-align: center;
          color: #fff;
          .arrow {
            color: #fff;
          }
        }
      }
      .slick-prev,
      .slick-next {
        z-index: 1;
        width: 27px;
      }
      .slick-prev::before,
      .slick-next::before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
      }
    }
    &-title {
      padding: $space-16 $space-0;
      z-index: 1;
      font-size: 60%;
      &-top {
        color: $primary_color_50;
        padding: $space-0 $space-16;
        font-size: 20px;
      }
      &-sub {
        display: flex;
        justify-content: center;
        background-color: $primary_color;
        color: $white;
        font-size: 32px;
        padding: 0 $space-16;
        border-radius: 10px;
      }
    }
    &-form {
      margin: 0 auto;
      padding: 0 2%;
      width: 50%;
      height: 100vh;
      overflow: hidden;
      overflow-y: scroll;
      position: relative;
      &-wrap {
        padding: 0 20%;
        .registration__sections {
          @include Flex($direction: row, $align: center);
          margin-top: 2%;
          margin-bottom: 3%;
          @keyframes prog {
            0% {
              width: 0%;
            }
            100% {
              width: 100%;
            }
          }
        }
      }
      &-title {
        text-align: center;
        padding: 10px 0px;
      }
      &-data {
        margin: $space-16 0;
        &-section {
          position: relative;
          padding: $space-16;
          background-color: $white;
          border-radius: $mradius;
          box-shadow: $dshadow;
          margin: 0 auto;
          &:not(:last-child) {
            margin-bottom: $space-16;
          }
          .selectFiles {
            &__photo {
              color: $primary_color;
              font-weight: bold;
              font-size: 12px;
            }
            &__wrapper {
              @include Flex(column, flex-end, flex-start);
              height: 100%;
              width: 100%;
            }
          }
        }
        &-input {
          position: relative;
          transition: all 0.08s ease-in-out;

          .container__inpt-tel {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          &:not(:last-child) {
            margin-bottom: $space-16;
          }
          [role="label"] {
            margin-bottom: $space-16/4;
            font-family: $sec_font;
            color: #5b5957;
            &[required]:after {
              content: "*";
              color: $primary_color;
              margin-left: 5px;
            }
          }
          .container-telInfo {
            display: flex;
            .postal-code {
              margin-right: 5px;
            }
          }
          input[dinput],
          input[type="text"],
          input[type="email"],
          input[type="tel"],
          input[type="date"],
          input[type="number"],
          input[type="file"],
          textarea {
            padding: $space-16/2;
            border: solid 1px $dusty_gray;
            border-radius: $mradius/2;
            width: 100%;
            font: 400 100% $first_font;
            box-shadow: 0 0 0;
            appearance: none;
            height: 37px;
            &::placeholder {
              color: #c8c8c8;
            }
            &.data-error {
              border-color: $primary_color;
            }
          }
          input[type="file"] {
            font-size: 75%;
          }
          [role="select-radio"] {
            [role="option"] {
              margin: 5px 0;
              label {
                margin-left: 5px;
              }
              input[role="other"][type="text"] {
                border-radius: 0;
                padding: 0;
                border: none;
                border-bottom: solid 1px;
              }
            }
          }
          textarea {
            min-height: 75px;
            resize: none;
          }
          .tooltip {
            position: relative;
            font-size: 75%;
            margin-top: 2px;
            color: $primary_color;
            font-weight: bold;
          }
        }
      }
      &-options {
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: center;
        padding-bottom: 15px;
        .button {
          width: 100%;
          max-width: 255px;
          margin: auto;
        }
      }
    }
    .slick-list {
      max-height: 100vh;
    }
    &-photos {
      display: grid;
      gap: $space-16;
      margin: $space-16 $space-0;
    }
    &-component {
      display: flex;
      gap: $space-16;
      position: relative;
    }
  }
  .message__error--city {
    margin-top: 5px;
    color: $primary_color;
  }
  body.signup {
    .router-link-exact-active.primary-button {
      pointer-events: none;
      background-color: $white;
      color: $primary_color;
      border: solid 1px;
    }
  }
  @media (max-width: $tablet_width) {
    .signup-section {
      &-title-sub {
        margin: 0 15px;
      }
      &-wrap {
        display: flex;
        flex-direction: column;
        .wrap-slider {
          width: 100%;
          height: 60vh;
          .slogan-c {
            position: absolute;
            top: 85%;
            font-size: 21px;
          }
          .imgSlider {
            height: 60vh;
            background-position: center 0;
          }
        }
      }
      &-form {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        &-title {
          padding-bottom: 10px;
        }
        &-data-section {
          margin: 0px 15px;
        }
        &-wrap {
          padding: 0;
        }
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .signup-section-form {
      &-wrap {
        padding: $space-0 $space-20;
      }
      &-data {
        &-section {
          .selectFiles {
            &__photo {
              color: $primary_color;
              font-weight: bold;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 769px) and (max-width: 980px) {
    .container {
      &__languageModal {
        display: block;
        top: -2px;
        right: 6px;
      }
    }
    .social-icons {
      margin-right: 12px;
    }
    .signup-section {
      .container-telInfo {
        .postal-code {
          width: 30%;
        }
      }
      &-title-sub {
        margin: 0 15px;
      }
      &-wrap {
        display: flex;
        flex-direction: column;
        .wrap-slider {
          width: 100%;
          margin-top: 0;
          height: 60vh;
          .imgSlider {
            height: 65vh;
            background-position: center 0;
          }
        }
      }
      &-form {
        width: 50%;
        height: 100%;
        margin: 0 auto;
        padding: 35px 0 0 0;
        &-title {
          padding-bottom: 10px;
        }
        &-data-section {
          margin: 0px 15px;
        }
        &-wrap {
          padding: 0;
        }
      }
    }
  }
  @media screen and (min-width: 1440px) {
    .signup-section {
      &-form-wrap {
        padding: $space-0 20%;
      }
      &-photos {
        max-width: inherit;
      }
    }
  }
  @media (min-width: 981px) and (max-width: 1365px) {
    .signup-section {
      &-wrap {
        .wrap-slider {
          .slogan {
            top: 90%;
            font-size: 1.7rem;
          }
        }
      }
    }
    .signup-section-wrap .wrap-title {
      padding: 15px 15px 10px;
    }
  }
  @media screen and (min-width: 981px) {
    .container {
      &__languageModal {
        display: block;
        top: -5px;
        right: 10px;
      }
    }
    .signup-section {
      &-wrap {
        .wrap-slider {
          margin-top: 0;
        }
      }
    }
    .signup-section-wrap .wrap-title {
      padding: 15px 15px 10px;
    }
  }
</style>
